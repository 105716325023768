module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Lato","Special Elite","Open Sans","Montserrat","Raleway"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":["gatsby-remark-embedder",{"resolve":"gatsby-remark-prismjs","options":{"inlineCodeMarker":"±"}},{"resolve":"gatsby-remark-images","options":{"withWebp":true}}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/builds/personal/websites/ryankes.eu/main"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"3","matomoUrl":"https://stats.ryankes.eu","siteUrl":"https://ryankes.eu"},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[],"specialChars":"/:"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-50843119-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-webmention/gatsby-browser.js'),
      options: {"plugins":[],"username":"ryankes.eu","identity":{"twitter":"alrayyes","github":"alrayyes","email":"ryan@ryankes.eu"},"mentions":true,"pingbacks":true,"domain":"ryankes.eu","token":"4D8DDoBc39Dnb0AYshOX0g"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
